import sleep from 'sleep-promise';

export async function request(url, method, data) {
    const runtimeConfig = useRuntimeConfig();
    const { apiToken } = useUserStore();

    const baseUrl = runtimeConfig.public.apiBaseUrl;

    if (url.indexOf('http') !== 0) {
        url = `${baseUrl}/${url}`;
    }

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    if (apiToken) {
        headers['Authorization'] = `Bearer ${apiToken}`;
    }

    const startTime = Date.now();

    const res = await $fetch(url, {
        method,
        headers,
        body: JSON.stringify(data),
    });

    const endTime = Date.now();
    const duration = endTime - startTime;
    console.log('request', url, method, data, res, `Duration: ${duration}ms`);

    return res;
}

export async function post(url, data) {
    return await request(url, 'POST', data);
}

export async function get(url, data) {
    return await request(url, 'GET', data);
}

export async function getUsersForDonorsCarousel() {
    return await get('users-for-donation-gallery');
}

export async function fetchPrefillData(hash) {
    const runtimeConfig = useRuntimeConfig();
   
    const baseUrl = runtimeConfig.public.hqBaseUrl;
    
    return await get(`${baseUrl}/get-prefill-data/${hash}`);
}

export async function loadUser(data) {
    const res = await post('load-user', data);

    await useUserStore().updateUser(res);

    return res;
}

export async function storeUser(data) {
    const utm = usePrefillStore().utm?.value || usePrefillStore().utm;

    const res = await post('front-users', { ...data, utm });

    await useUserStore().updateUser(res);

    return res;
}

export async function storeAnonymousUser(data) {
    console.log('api.storeAnonymousUser', data);

    const utm = usePrefillStore().utm?.value;

    const res = await post('front-users/anonymous', { ...data, utm });

    await useUserStore().updateUser(res);

    return res;
}

export async function updateUser(data) {
    let { apiToken } = useUserStore();

    while (!apiToken) {
        console.log('Waiting for apiToken to be present.');

        // updateUser() requires apiToken, so we wait for the user to
        // be initiated by app.vue before calling this.
        await sleep(100);

        apiToken = useUserStore().apiToken;
    }

    const res = await post('front-users/update', data);
    await useUserStore().updateUser(res);
}

export async function recordTimestamp(code) {
    console.log('%c recordTimestamp: ', 'background: #000; color: #7dd3fc;', code);

    const user = useUserStore();

    if (user.timestamps[code]) {
        console.log('%c recordTimestamp: ', 'background: #000; color: #7dd3fc;', code, 'already recorded');

        return;
    }

    await updateUser({ timestamps: { [code]: null } });
}

export async function recordReturn(data) {
    return await post('front-users/record-return', data);
}

export async function recordInvalidPhone(invalidPhone) {
    if (!invalidPhone) {
        return;
    }

    return await post('front-users/record-invalid-phone', {
        invalid_phone: invalidPhone,
    });
}

export async function recordEmailSuggestion(providedEmail, suggestedEmail) {
    return await post('front-users/record-email-suggestion', {
        provided_email: providedEmail,
        suggested_email: suggestedEmail,
    });
}

export async function recordAcceptedEmailSuggestion(providedEmailValue, acceptedSuggestedEmailValue) {
    return await post('front-users/record-accepted-email-suggestion', {
        provided_email: providedEmailValue,
        accepted_email: acceptedSuggestedEmailValue,
    });
}

export async function confirmEmail(data) {
    const res = await post('front-users/confirm-email', data);

    await useUserStore().updateUser(res);

    return res;
}

export async function unsubscribe(data) {
    const res = await post('front-users/unsubscribe', data);

    await useUserStore().updateUser(res);

    return res;
}

export async function markAsRegularDonor() {
    await updateUser({ donated_regular: true });
}

export async function markAsNotRegularDonor() {
    await useUserStore().updateUser({ donated_regular: false });
}

export async function markAsSingleDonor() {
    await updateUser({ donated_single: true });
}

export async function submitFeedback(data) {
    await post('front-users/feedback', data);
}

// export async function markAsNotDonor() {
//     await useUserStore().updateUser({ donated_single_at: null });
// }
