import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2';
import Cookies from 'js-cookie';
// import cookie from 'cookie';

export default defineNuxtPlugin(({ $pinia }) => {
    $pinia.use(
        createPersistedStatePlugin({
            storage: {
                getItem: (key) => {
                    const value = Cookies.get(key);

                    console.log('%c Cookies.get ', 'background: #222; color: #bada55', key, value);

                    return value;

                    // // See https://nuxtjs.org/guide/plugins/#using-process-flags
                    // if (process.server) {
                    //     const parsedCookies = cookie.parse(useRequestHeaders(['cookie']).cookie);
                    //
                    //     return parsedCookies[key];
                    // }
                    // else {
                    //     return Cookies.get(key);
                    // }
                },
                // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
                setItem: function (key, value) {
                    console.log('%c Cookies.set ', 'background: #222; color: #bada55', key, value);

                    Cookies.set(key, value, { expires: 365, secure: false });
                },
                removeItem: function (key) {
                    console.log('%c Cookies.remove ', 'background: #222; color: #bada55', key);

                    Cookies.remove(key);
                },
            },
        }),
    );
});
