<template>
    <div
        class="font-secondary text-brown-100 bg-repeat overflow-hidden bg-[url(/images/page-bg.jpeg)] bg-[size:500%] lg:bg-[size:100%]"
    >
        <!--<button @click="clearWebCookies">Clear session</button>-->

        <!--<a to="/contacts" class="block py-2 text-center">Contacts</NuxtLink>-->

        <NuxtPage />

        <!--<NuxtPage v-if="showLoading === false" />-->
        <!--<div v-if="showLoading === true" class="flex justify-center items-center h-[100vh]">-->
        <!--    <span class="text-33">Loading...</span>-->
        <!--</div>-->

        <modals-container></modals-container>

        <div
            v-if="debugModalShown"
            class="fixed z-50 p-2 left-4 right-4 top-1/2 -translate-y-1/2 bg-white border rounded shadow-md max-h-[95vh] overflow-y-auto"
        >
            <div class="text-red-100 italic">
                <a @click.prevent="markAsRegularDonor" class="block cursor-pointer hover:underline">Mark user as regular donor</a>
                <a @click.prevent="markAsNotRegularDonor" class="block text-red-100 italic cursor-pointer hover:underline">Mark user as NOT regular donor (temporary)</a>
                <a @click.prevent="updateUser({ finished_writing: true })" class="block text-red-100 italic cursor-pointer hover:underline">Mark all companies as done</a>
                <a @click.prevent="updateUser({ finished_writing: false })" class="block text-red-100 italic cursor-pointer hover:underline">Mark all companies as NOT done</a>
                <a @click.prevent="updateUser({ companies_contact_method: null })" class="block text-red-100 italic cursor-pointer hover:underline">Unset companiesContactMethod</a>
                <a @click.prevent="clearWebCookies" class="block text-red-100 italic cursor-pointer hover:underline">Clear cookies</a>
            </div>

            <pre class="text-12 leading-tight">user: {{ storeToRefs(user) }}</pre>
            <pre class="text-12 leading-tight">prefill: {{ storeToRefs(prefill) }}</pre>
        </div>
    </div>
</template>

<script setup>
    import { ModalsContainer } from 'vue-final-modal';
    import { onKeyDown } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { markAsNotRegularDonor, markAsRegularDonor, storeAnonymousUser, updateUser } from '~/src/api';
    // import { usePageStore } from '~/stores/page';
    import { useUserStore } from '~/stores/user';

    // TODO: loading bar?

    const user = useUserStore();
    const prefill = usePrefillStore();

    const debugModalShown = ref(false);
    onKeyStroke(e => {
        if (e.code === 'KeyD' && e.altKey) {
            debugModalShown.value = !debugModalShown.value;
        }
    });
    onKeyDown('Escape', () => {
        debugModalShown.value = false;
    });

    const clearWebCookies = () => {
        const names = ['history', 'user', 'prefill', 'cookie_consent_user_accepted'];

        names.forEach(name => {
            const cookie = useCookie(name);
            cookie.value = null;
        });
    };

    if (typeof window !== 'undefined') {
        const { x, y } = JSON.parse(localStorage.getItem('scrollPositions') || '{}');

        window.scrollTo(x, y);

        window.addEventListener('scroll', (event) => {
            const scrollPositionsJSON = JSON.stringify({
                x: window.scrollX,
                y: window.scrollY,
            });
            localStorage.setItem('scrollPositions', scrollPositionsJSON);
        });
    }

    // const toggleShowLoading = value => showLoading.value = value;

    const processPrefillingAndAnonymous = async () => {
        const query = useRoute().query;

        // User has returned, no need to do any pre-filling and creating anonymous account.
        if (query.uid && query.t && query.p) {
            console.log('User has returned, stop processing.');

            return;
        }

        const { name: routeName } = useRoute();
        const router = useRouter();
        const uniqueHash = query?.h;

        // Remove ?h from the URL, so if this link is forwarded, other people can't see the prefill data.
        if (uniqueHash) {
            console.log('Remove uniqueHash from the URL');

            router.replace({ query: { ...query, h: undefined } });
        }

        // If user has no account yet...
        if (!user.apiToken) {
            console.log('User has no apiToken', user.apiToken, { ...user });

            // ...and came with utm, store it for later.
            if (query.utm_source || query.utm) {
                await usePrefillStore().setUtm(query.utm_source || query.utm);
            }

            // ...and came with a unique_hash link and has not been fetched yet, fetch the pre-fill data.
            if (uniqueHash && !prefill.hasFull) {
                await usePrefillStore().fetchFromApi(query.h);
            }

            // ...and is past the contact form, we need to create an account.
            if (!['index', 'contacts', 'claims'].includes(routeName)) {
                await storeAnonymousUser();
            }
        }
    }

    // await useAsyncData(() => {
    //     processPrefillingAndAnonymous();
    // }, { server: false });

    onMounted(() => {
        // const { setCookieModalActive } = usePageStore();

        // setInterval(function () {
        //     setCookieModalActive($('.freeprivacypolicy-com---nb-interstitial-overlay').css('display') === 'block');
        // }, 200);

        processPrefillingAndAnonymous();
    });
</script>
