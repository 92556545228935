import { defineStore, acceptHMRUpdate } from 'pinia';

export const useUserStore = defineStore('user', () => {
    // State from API.
    // const id = ref(null);
    const publicId = ref(null);
    // const token = ref(null);
    const firstName = ref(null);
    const lastName = ref(null);
    const phone = ref(null);
    const email = ref(null);
    const apiToken = ref(null);
    const isRegularDonor = ref(false); // Has active subscription in "Tušti narvai".
    const donatedSingle = ref(false);
    const donatedRegular = ref(false);
    const isActiveSubscriber = ref(false);
    const emailConfirmedAt = ref(null);
    const opinion = ref(null);
    const amountOnce = ref(null);
    const amountRegular = ref(null);
    const companies = ref([]);
    const companiesDone = ref([]);
    const companiesContactMethod = ref(null);
    const finishedWriting = ref(false);
    const unsubscribedAt = ref(null);
    const timestamps = ref({});
    const filledFeedbackFormAt = ref(null);

    // Client-only state.
    const lastPaymentTypeIsRegular = ref(null);

    // Getters.
    const fullName = computed(() => {
        if (firstName.value && lastName.value) {
            return `${firstName.value} ${lastName.value}`.trim();
        }

        return firstName.value ?? lastName.value ?? null;
    });
    const lastAmount = computed(() => lastPaymentTypeIsRegular.value ? amountRegular.value : amountOnce.value);

    function updateUser(data) {
        const map = {
            // id: 'id',
            publicId: 'public_id',
            // token: 'token',
            firstName: 'first_name',
            lastName: 'last_name',
            phone: 'phone',
            email: 'email',
            apiToken: 'api_token',
            isRegularDonor: 'became_regular_donor_at',
            donatedSingle: 'donated_single',
            donatedRegular: 'donated_regular',
            isActiveSubscriber: 'is_active_subscriber',
            emailConfirmedAt: 'email_confirmed_at',
            opinion: 'opinion',
            amountOnce: 'amount_once',
            amountRegular: 'amount_regular',
            companies: 'companies',
            companiesDone: 'companies_done',
            companiesContactMethod: 'companies_contact_method',
            finishedWriting: 'finished_writing',
            unsubscribedAt: 'unsubscribed_at',
            timestamps: 'timestamps',
            filledFeedbackFormAt: 'filled_feedback_form_at',
        };

        for (const i in data) {
            if (Object.values(map).includes(i)) {
                const key = Object.keys(map).find(key => map[key] === i);
                const value = data[i];
                let currentValue = this[key];

                currentValue = currentValue !== null && typeof currentValue === 'object' ? toRaw(currentValue) : currentValue;

                console.log(`updateUser: maybe set ${key} to ${data[i] instanceof Object ? JSON.stringify(data[i]) : data[i]}`);

                if (currentValue !== value) {
                    this[key] = value;
                    console.log(`updateUser: set ${key} to ${data[i] instanceof Object ? JSON.stringify(data[i]) : data[i]}`);
                }
            }
        }

        // isRegularDonor comes from HQ, but that data may not be up to date.
        // So if we see that user donated a regular amount on this website, treat him as regular donor.
        if (isRegularDonor.value === false && donatedRegular.value !== null) {
            isRegularDonor.value = true;

            console.log('updateUser: set isRegularDonor to true, because donatedRegular is not null');
        }
    }

    return {
        // State
        // id,
        publicId,
        // token,
        firstName,
        lastName,
        phone,
        email,
        apiToken,
        isRegularDonor,
        donatedSingle,
        donatedRegular,
        isActiveSubscriber,
        emailConfirmedAt,
        opinion,
        amountOnce,
        amountRegular,
        companies,
        companiesDone,
        companiesContactMethod,
        finishedWriting,
        unsubscribedAt,
        timestamps,
        filledFeedbackFormAt,
        lastPaymentTypeIsRegular,

        // Getters
        fullName,
        lastAmount,

        // Actions
        updateUser,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
