import { defineStore, acceptHMRUpdate } from 'pinia';
import { fetchPrefillData } from '~/src/api';

export const usePrefillStore = defineStore('prefill', () => {
    const hash = ref(null);
    const firstName = ref(null);
    const lastName = ref(null);
    const phone = ref(null);
    const email = ref(null);
    const utm = ref(null);

    const hasFull = computed(() => firstName.value && lastName.value && phone.value && email.value);

    async function fetchFromApi(hashValue) {
        hash.value = hashValue;

        const data = await fetchPrefillData(hash.value);

        firstName.value = data.first_name;
        lastName.value = data.last_name;
        phone.value = data.phone;
        email.value = data.email;

        console.log('fetchFromApi:', hashValue, data);
    }

    async function setUtm(utmValue) {
        utm.value = utmValue;

        console.log('setUtm:', utm.value);
    }

    return {
        // State
        hash,
        firstName,
        lastName,
        phone,
        email,
        utm,

        // Getters
        hasFull,

        // Actions
        fetchFromApi,
        setUtm,
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePrefillStore, import.meta.hot));
}
