import { default as claimsg0CzX2y4psMeta } from "/vercel/path0/pages/claims.vue?macro=true";
import { default as companiesd8HmMpceeyMeta } from "/vercel/path0/pages/companies.vue?macro=true";
import { default as contactsvmO9gwdyLiMeta } from "/vercel/path0/pages/contacts.vue?macro=true";
import { default as donatetrRz1WBTGTMeta } from "/vercel/path0/pages/donate.vue?macro=true";
import { default as feedback_45formgkDXRmLIJJMeta } from "/vercel/path0/pages/feedback-form.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as opinionxNU0pM3Vv0Meta } from "/vercel/path0/pages/opinion.vue?macro=true";
import { default as returnXo66hSFG53Meta } from "/vercel/path0/pages/return.vue?macro=true";
import { default as sharev8ONwuKEHBMeta } from "/vercel/path0/pages/share.vue?macro=true";
import { default as should_45confirm_45emailDIhVJnMXLGMeta } from "/vercel/path0/pages/should-confirm-email.vue?macro=true";
import { default as thanksRFiywdtFQmMeta } from "/vercel/path0/pages/thanks.vue?macro=true";
import { default as unsubscribedZE1ohsoC9eMeta } from "/vercel/path0/pages/unsubscribed.vue?macro=true";
import { default as writeYa1QaQrf7zMeta } from "/vercel/path0/pages/write.vue?macro=true";
export default [
  {
    name: claimsg0CzX2y4psMeta?.name ?? "claims",
    path: claimsg0CzX2y4psMeta?.path ?? "/claims",
    children: [],
    meta: claimsg0CzX2y4psMeta || {},
    alias: claimsg0CzX2y4psMeta?.alias || [],
    redirect: claimsg0CzX2y4psMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/claims.vue").then(m => m.default || m)
  },
  {
    name: companiesd8HmMpceeyMeta?.name ?? "companies",
    path: companiesd8HmMpceeyMeta?.path ?? "/companies",
    children: [],
    meta: companiesd8HmMpceeyMeta || {},
    alias: companiesd8HmMpceeyMeta?.alias || [],
    redirect: companiesd8HmMpceeyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/companies.vue").then(m => m.default || m)
  },
  {
    name: contactsvmO9gwdyLiMeta?.name ?? "contacts",
    path: contactsvmO9gwdyLiMeta?.path ?? "/contacts",
    children: [],
    meta: contactsvmO9gwdyLiMeta || {},
    alias: contactsvmO9gwdyLiMeta?.alias || [],
    redirect: contactsvmO9gwdyLiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: donatetrRz1WBTGTMeta?.name ?? "donate",
    path: donatetrRz1WBTGTMeta?.path ?? "/donate",
    children: [],
    meta: donatetrRz1WBTGTMeta || {},
    alias: donatetrRz1WBTGTMeta?.alias || [],
    redirect: donatetrRz1WBTGTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/donate.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formgkDXRmLIJJMeta?.name ?? "feedback-form",
    path: feedback_45formgkDXRmLIJJMeta?.path ?? "/feedback-form",
    children: [],
    meta: feedback_45formgkDXRmLIJJMeta || {},
    alias: feedback_45formgkDXRmLIJJMeta?.alias || [],
    redirect: feedback_45formgkDXRmLIJJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    children: [],
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: opinionxNU0pM3Vv0Meta?.name ?? "opinion",
    path: opinionxNU0pM3Vv0Meta?.path ?? "/opinion",
    children: [],
    meta: opinionxNU0pM3Vv0Meta || {},
    alias: opinionxNU0pM3Vv0Meta?.alias || [],
    redirect: opinionxNU0pM3Vv0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/opinion.vue").then(m => m.default || m)
  },
  {
    name: returnXo66hSFG53Meta?.name ?? "return",
    path: returnXo66hSFG53Meta?.path ?? "/return",
    children: [],
    meta: returnXo66hSFG53Meta || {},
    alias: returnXo66hSFG53Meta?.alias || [],
    redirect: returnXo66hSFG53Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/return.vue").then(m => m.default || m)
  },
  {
    name: sharev8ONwuKEHBMeta?.name ?? "share",
    path: sharev8ONwuKEHBMeta?.path ?? "/share",
    children: [],
    meta: sharev8ONwuKEHBMeta || {},
    alias: sharev8ONwuKEHBMeta?.alias || [],
    redirect: sharev8ONwuKEHBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/share.vue").then(m => m.default || m)
  },
  {
    name: should_45confirm_45emailDIhVJnMXLGMeta?.name ?? "should-confirm-email",
    path: should_45confirm_45emailDIhVJnMXLGMeta?.path ?? "/should-confirm-email",
    children: [],
    meta: should_45confirm_45emailDIhVJnMXLGMeta || {},
    alias: should_45confirm_45emailDIhVJnMXLGMeta?.alias || [],
    redirect: should_45confirm_45emailDIhVJnMXLGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/should-confirm-email.vue").then(m => m.default || m)
  },
  {
    name: thanksRFiywdtFQmMeta?.name ?? "thanks",
    path: thanksRFiywdtFQmMeta?.path ?? "/thanks",
    children: [],
    meta: thanksRFiywdtFQmMeta || {},
    alias: thanksRFiywdtFQmMeta?.alias || [],
    redirect: thanksRFiywdtFQmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/thanks.vue").then(m => m.default || m)
  },
  {
    name: unsubscribedZE1ohsoC9eMeta?.name ?? "unsubscribed",
    path: unsubscribedZE1ohsoC9eMeta?.path ?? "/unsubscribed",
    children: [],
    meta: unsubscribedZE1ohsoC9eMeta || {},
    alias: unsubscribedZE1ohsoC9eMeta?.alias || [],
    redirect: unsubscribedZE1ohsoC9eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/unsubscribed.vue").then(m => m.default || m)
  },
  {
    name: writeYa1QaQrf7zMeta?.name ?? "write",
    path: writeYa1QaQrf7zMeta?.path ?? "/write",
    children: [],
    meta: writeYa1QaQrf7zMeta || {},
    alias: writeYa1QaQrf7zMeta?.alias || [],
    redirect: writeYa1QaQrf7zMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/write.vue").then(m => m.default || m)
  }
]